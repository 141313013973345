import React from 'react';
import ReactDOM from 'react-dom';
import ReactMarkdown from 'react-markdown';
import source from './howTo.md';
import 'materialize-css/dist/css/materialize.css';
import 'src/highlight/styles/github.css';
import hljs from 'src/highlight/highlight.pack.js';


const App = () =>  (
  <ReactMarkdown
    source={source}
    escapeHtml={false}
  />
);

hljs.initHighlightingOnLoad();

const comp = document.getElementById('howto');
if (comp && comp !== null) {
  ReactDOM.render(<App />, comp);
}
